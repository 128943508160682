import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { WebsiteName, fontWeightType } from '../res/Consts'
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box'
import { BrowserView, MobileView } from "react-device-detect"
import Menu from './Menu'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        clear: "both",
        position: 'fixed',
        top: "0px",
        left: "0px",
        width: '100vw',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    titlebar: {
        display: 'flex',
        justifyContent: 'space-between',
        // alignItems: 'center',
        // alignContent: 'center',
        // minHeight: '40px',
        flexWrap: 'wrap',
        flexGrow: '1'
        // margin:'5px'
    },
    button: {
        // margin: theme.spacing(1),
        textTransform: "none",
        "&:hover": { backgroundColor: "transparent" }
    },
    input: {
        display: 'none',
    },
}));

function rsize(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

export default function Header(props) {
    const classes = useStyles();
    const { setPage } = props;

    const [dimensions, setDimensions] = React.useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
      React.useEffect(() => {
        const debouncedHandleResize = rsize(function handleResize() {
          setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
          })
        }, 300)
    
        window.addEventListener('resize', debouncedHandleResize)
    
        return _ => {
          window.removeEventListener('resize', debouncedHandleResize)
        
      }})

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar variant="dense" className={classes.titlebar}>
                    <Button
                        disableRipple={true}
                        disableFocusRipple={true}
                        variant="text"
                        onClick={() => { setPage(0) }}
                        className={classes.button}>
                        <Typography variant="h6" color="inherit">
                            <Box
                                letterSpacing={3}
                                fontWeight={fontWeightType}>
                                {WebsiteName}
                            </Box>
                        </Typography>
                    </Button>
                    <div>
                        {
                            dimensions.width > 600 ?
                                <BrowserView>
                                    <Button
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                        variant="text"
                                        onClick={() => { setPage(1) }}
                                        className={classes.button}>
                                        <Box
                                            letterSpacing={2}
                                            fontWeight={fontWeightType}>
                                            A.n.i B
                                </Box>
                                    </Button>
                                    <Button
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                        variant="text"
                                        onClick={() => { setPage(2) }}
                                        className={classes.button}>
                                        <Box
                                            letterSpacing={2}
                                            fontWeight={fontWeightType}>
                                            M.y.l i
                                </Box>
                                    </Button>
                                    <Button
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                        variant="text"
                                        onClick={() => { setPage(3) }}
                                        className={classes.button}>
                                        <Box
                                            letterSpacing={2}
                                            fontWeight={fontWeightType}>
                                            W.a.v B
                                </Box>
                                    </Button>
                                </BrowserView> 
                                 :
                                 <BrowserView>
                                 <Menu setPage={setPage} />
                                 </BrowserView>
                         }
                        <MobileView>
                            {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                                <MenuIcon />
                            </IconButton> */}
                            <Menu setPage={setPage} />
                        </MobileView>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}
