import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '40px',
    marginRight: '30px',
    marginLeft: '30px',
    height: '100%',
    width: '100%'
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },
  links: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  box: {
  },
  youtube: {
    padding: '5px',
    fontSize: 60,
    color: '#F80000'
  },
  linkedin: {
    padding: '5px',
    fontSize: 60,
    color: '#0172AF'
  },
  contact_info: {
    maxWidth: '400px',
    width: '60vw'
  }
}));

export default function ContactUs() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.page} >
        <div className={classes.box}>
          <Box
            letterSpacing={2}
            fontWeight="fontWeightBold">
            <Typography varient="h4" className={classes.contact_info}>
              Contact at fouriermachines@gmail.com
          <div className={classes.links}>
              <Typography varient="h4" >tej @ </Typography>
                <Link
                  target="_blank"
                  href="http://linkedin.com/in/tej-kiran-43722910b" >
                  <LinkedInIcon className={classes.linkedin} />
                </Link>
                <Link
                  target="_blank"
                  href="https://www.youtube.com/channel/UCNSxrMLfff3ku3ZD0ZCjrtA" >
                  <YouTubeIcon className={classes.youtube} />
                </Link>
              </div>
            </Typography>
          </Box>
        </div>
      </Paper>
    </div>
  );
}