import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography, Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Link from '@material-ui/core/Link'
import YouTubeIcon from '@material-ui/icons/YouTube';
import YouTube from './YouTube'
import HomeIcon from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '20px',
        marginBottom: '10px',
        width: '100%'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        justifyItems: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: '20px',
        // background: '#f7f9f9',
    },
    paper1: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: '20px',
        overflow: 'auto',
        // background: '#f7f9f9',
    },
    paper1_container: {

    },
    contactus: {
    },
    title: {
        marginBottom: '20px',
    },
    info: {
        paddingTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth:'600px',
        width: '60vw',
    },
    info2: {
        paddingTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        maxWidth:'600px',
        width: '60vw',
    },
    info1: {
        paddingTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth:'600px',
        width: '60vw',
    },
    button: {
        margin: theme.spacing(1),
        textTransform: "none",
        "&:hover": { backgroundColor: "transparent" }
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    logo_big: {
        display: 'block',
        maxWidth: '450px',
        width: "90vw",
        height: 'auto',
        marginBottom: '20px'
    },
    logo: {
        display: 'block',
        maxWidth: '250px',
        width: "50vw",
        height: 'auto',
        marginBottom: '20px'
    },
    more: {
        // display: 'flex',
        // flexDirection: 'row',
        // alignItems: 'center',
        // paddingTop: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'flex-end',
        alignItems:'center',
        maxWidth:'650px',
        width:'70vw',
        cursor: "pointer"
    },
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '40px',
        // paddingTop: '20px',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '40px',
        paddingTop: '20px',
    },
    details1: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        paddingBottom: '40px',
        paddingTop: '20px',
    },
    imgs: {
        display: 'block',
        maxWidth: "70vw",
        maxHeight:'50vh',
        // maxHeight: "500px",
        width: "auto",
        height: 'auto',
        marginBottom: '20px'
    },
    divider: {
        marginTop: '20px',
        width: "75vw",
    },
    link: {
        // paddingTop: '10px',
        // display: 'flex',
        // flexDirection: 'row',
        // alignItems: 'center',
        // cursor: "pointer"
        paddingTop: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'flex-end',
        alignItems:'center',
        maxWidth:'650px',
        width:'70vw',
        cursor: "pointer"
    },
    linkedin: {
        marginTop: '10px',
        fontSize: 60,
    },
    navi_container:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width:'100vw'
    },
    navi: {
        // position: 'relative',
        // bottom: "45px",
        // left: "0px",
        marginLeft: "20px",
        marginBottom: "20px"
    },
    navi_link: {
        cursor: "pointer"
    }
}
));

export default function AniC(props) {
    const classes = useStyles();
    const { summary, setPage } = props;
    const [isOpen, setOpen] = React.useState(false)

    return (
        <div>
        <div className={classes.root}>
            {/* <Typography variant="h4" className={classes.title}>
                    <Box
                        letterSpacing={2}
                        fontWeight={fontWeightType}>
                        A.n.i C
                    </Box>
                </Typography> */}
            {summary === true ?
                <Paper
                    elevation={0}
                    className={classes.paper}>
                    <div className={classes.paper}>
                        <img src="./res/img/Ani_B_S.jpg" alt="Ani B" className={classes.logo} />
                        <Box
                            className={classes.contactus}
                            letterSpacing={3}
                            fontWeight="fontWeightMedium">
                            <Typography>
                                Emotion, Acting and Games
                    </Typography>
                        </Box>
                        {/* <Skeleton variant="rect" width={800} height={400} /> */}
                        <img src="./res/img/ANI/ANI_MAIN_PIC.jpg" alt="Ani B" className={classes.imgs} />
                        <div className={classes.content}>
                            <div className={classes.info}>
                                <Typography>
                                    An animatable robot that talks with expressions synchronised to emotions in the conversation. Choose custom  attitude and more.
                    </Typography>
                                {summary === true ?
                                    <div className={classes.more}>
                                        <Button
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            variant="text"
                                            onClick={() => { setPage(1) }}
                                            className={classes.button}>
                                            <Typography variant="h6">
                                                Find More
                                    </Typography>
                                            <ArrowRightIcon fontSize="large" color="secondary" />
                                        </Button>
                                    </div> : <div />
                                }
                            </div>
                        </div>
                    </div>
                </Paper>
                :
                <Paper
                    elevation={0}
                    className={classes.paper1}
                >
                    <div
                        className={classes.paper1_container}
                    >
                        {/* ANI B info */}
                        <paper className={classes.details}>
                            <img src="./res/img/Ani_B_S.jpg" alt="Ani B" className={classes.logo} />

                            <div className={classes.info}>
                                <Box
                                    className={classes.contactus}
                                    letterSpacing={1}
                                    fontWeight="fontWeightMedium">
                                    <Typography>
                                        Conversation robot that talks with attitude chosen by you.
                            </Typography>
                                </Box>
                            </div>
                            <img src="./res/img/ANI/ANI_B_PIC.jpg" alt="Ani B" className={classes.imgs} />
                            <div className={classes.content}>
                                <div className={classes.info}>
                                    <Typography>
                                        Select attitude of A.n.i B  from preloaded options or create a customised attitude using EmSynth feature in A.n.i Dream app.
                                        </Typography>
                                </div>
                            </div>
                            <Divider className={classes.divider} />
                        </paper>
                        {/* ANI C info */}
                        <paper className={classes.details}>
                            <img src="./res/img/Ani_C_S.jpg" alt="Ani C" className={classes.logo} />

                            <div className={classes.info}>
                                <Box
                                    className={classes.contactus}
                                    letterSpacing={1}
                                    fontWeight="fontWeightMedium">
                                    <Typography>
                                        A cloud designed to control and exchange data between multiple machines
                            </Typography>
                                </Box>
                            </div>
                            <img src="./res/img/ANI/ANI_C_POSTER.jpg" alt="Ani C" className={classes.imgs} />
                            <div className={classes.content}>
                                <div className={classes.info}>
                                    <Typography>
                                        A Fourier Machines Cloud to control registered devices through A.n.i B
                                        </Typography>
                                    <Link
                                        onClick={() => setOpen(true)}
                                        color="secondary" >
                                        <div className={classes.link}>
                                            <Typography variant="h5">
                                                Watch Demos
                                                </Typography>
                                            <YouTubeIcon style={{ fontSize: 60 }} />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <Divider className={classes.divider} />
                        </paper>

                        {/* Ani Dream info */}
                        <paper className={classes.details}>
                            <img src="./res/img/Ani_Dream_S.jpg" alt="Ani Dream" className={classes.logo_big} />

                            <div className={classes.info}>
                                <Box
                                    className={classes.contactus}
                                    letterSpacing={1}
                                    fontWeight="fontWeightMedium">
                                    <Typography>
                                        An iPAD app to create new Attitudes and Choreograms for A.n.i B
                            </Typography>

                                </Box>
                            </div>
                            <img src="./res/img/ANI/ANI_DREAM_POSTER.jpg" alt="Ani Dream" className={classes.imgs} />
                            <div className={classes.content}>
                                <div className={classes.info}>
                                    <Typography>
                                    </Typography>
                                </div>
                            </div>
                            <Divider className={classes.divider} />
                        </paper>

                        <paper className={classes.details1}>
                            <img src="./res/img/SuperReality_S.jpg" alt="Super Reality" className={classes.logo_big} />
                            {/* <Skeleton variant="rect" width={800} height={400} /> */}
                            <div className={classes.content}>
                                <div className={classes.info1}>
                                    <Typography variant="h5" noWrap={true}>
                                        <Box
                                            className={classes.contactus}
                                            letterSpacing={6}
                                            fontWeight="fontWeightMedium">
                                            Coming Soon
                                            </Box>
                                    </Typography>
                                </div>
                            </div>
                        </paper>
                    </div>
                </Paper>
            }
            <YouTube isOpen={isOpen} setOpen={setOpen} src="https://www.youtube.com/embed/sLqDf-IARrI" />
        </div>
                {
                    summary === true ? <div />:
                <div className={classes.navi_container}>
                        <Breadcrumbs aria-label="breadcrumb" className={classes.navi}>
                            <Link color="inherit" className={classes.navi_link} onClick={() => { setPage(0) }} >
                                Home
                            </Link>
                            <Typography color="textPrimary">A.n.i B</Typography>
                        </Breadcrumbs>
                </div>
                }
        </div>
    );
}