import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Link from '@material-ui/core/Link'
import YouTubeIcon from '@material-ui/icons/YouTube';
import YouTube from './YouTube'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '20px',
        marginBottom: '10px',
        width: '100%'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        paddingTop: '20px',
        overflow: 'auto',
        // background: '#f7f9f9',
    },
    title: {
        marginBottom: '20px',
    },
    info: {
        paddingTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '60%'

    },
    button: {
        margin: theme.spacing(1),
        textTransform: "none",
        "&:hover": { backgroundColor: "transparent" }
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    logo: {
        display: 'block',
        maxWidth: '250px',
        width: "50vw",
        height: 'auto',
        marginTop: '20px',
        marginBottom: '20px'
    },
    more: {
        // display: 'flex',
        // flexDirection: 'row',
        // alignItems: 'center',
        paddingTop: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'flex-end',
        alignItems:'center',
        maxWidth:'650px',
        width:'70vw',
        cursor: "pointer"
    },
    bottom: {
        paddingBottom: '20px'
    },
    imgs: {
        display: 'block',
        maxWidth: "70vw",
        maxHeight: '50vh',
        // maxHeight: "500px",
        width: "auto",
        height: 'auto',
        marginBottom: '20px'
    },
    link: {
        paddingTop: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'flex-end',
        alignItems:'center',
        maxWidth:'650px',
        width:'70vw',
        cursor: "pointer"
    },
    navi_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100vw'
    },
    navi: {
        marginLeft: "20px",
        marginBottom: "20px"
    },
    navi_link: {
        cursor: "pointer"
    },
    info: {
        paddingTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '60vw',
        maxWidth:'600px',
        marginBottom:'20px'
    },
    info2: {
        paddingTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'center',
        alignContent:'center',
        alignItems: 'center',
        width: '90vw',
        maxWidth:'600px',
        marginBottom:'20px'
    },
}
));

export default function Myli(props) {
    const classes = useStyles();
    const [isOpen, setOpen] = React.useState(false)
    const { summary, setPage } = props;

    return (
        <div>
            <div className={classes.root}>
                <Paper
                    elevation={0}
                    className={classes.paper}>
                    {/* <Typography variant="h4" className={classes.title}>
                    <Box
                        letterSpacing={2}
                        fontWeight={fontWeightType}>
                        A.n.i C
                    </Box>
                </Typography> */}
                    <img src="./res/img/Myl_i_S.jpg" alt="Myl i" className={classes.logo} />
                    <Box
                        className={classes.contactus}
                        letterSpacing={3}
                        fontWeight="fontWeightMedium">
                        <Typography>
                            Sway and Play
                </Typography>
                    </Box>
                    <img src="./res/img/MYL/MYL_MAIN.jpg" alt="Myl i" className={classes.imgs} />
                    <div className={classes.content}>
                        <div className={classes.info}>
                            <Typography>
                                A wearable input device for multi gesture and multi axis control with haptic feedback.
                    </Typography>
                            {summary === true ?
                                <Button
                                    disableRipple={true}
                                    disableFocusRipple={true}
                                    variant="text"
                                    onClick={() => { setPage(2) }}
                                    className={classes.button}>
                                    <div className={classes.more}>
                                        <Typography variant="h6">
                                            Find More
                                    </Typography>
                                        <ArrowRightIcon fontSize="large" color="secondary" />
                                    </div>
                                </Button> : <div className={classes.bottom} >
                                    <Link color="secondary"
                                        onClick={() => setOpen(true)}
                                    // href="https://youtu.be/H9LbpLnDdwc"
                                    >
                                        <div className={classes.link}>
                                            <Typography variant="h6">
                                                Watch Demos
                                                </Typography>
                                            <YouTubeIcon style={{ fontSize: 60 }} />
                                        </div>
                                    </Link>
                                </div>
                            }
                            {summary == false ?
                                <div className={classes.info2}>
                                    <Typography variant="h4">
                                        Generation 2 in development
                                           </Typography>
                                </div>
                                : <div />
                            }
                        </div>
                    </div>
                </Paper>
                <YouTube isOpen={isOpen} setOpen={setOpen} src="https://www.youtube.com/embed/watch?v=1BnNJd-cfS8&list=PLWHKSAeeh7VUM6aLTFDNP_9SPJS2RJ5GR" />
            </div>
            {
                summary === true ? <div /> :
                    <div className={classes.navi_container}>
                        <Breadcrumbs aria-label="breadcrumb" className={classes.navi}>
                            <Link color="inherit" className={classes.navi_link} onClick={() => { setPage(0) }} >
                                Home
                            </Link>
                            <Typography color="textPrimary">M.y.l i</Typography>
                        </Breadcrumbs>
                    </div>
            }
        </div>
    );
}