import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'react-modal-video/scss/modal-video.scss'
import Dialog from '@material-ui/core/Dialog';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { BrowserView, MobileView } from "react-device-detect"

const useStyles = makeStyles(theme => ({
    youtube: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}
));

function rsize(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

export default function YouTube(props) {
    const classes = useStyles();
    const { isOpen, setOpen, src } = props;
    const handleClose = () => {
        setOpen(false)
    };

    const [dimensions, setDimensions] = React.useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
      React.useEffect(() => {
        const debouncedHandleResize = rsize(function handleResize() {
          setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
          })
        }, 1000)
    
        window.addEventListener('resize', debouncedHandleResize)
    
        return _ => {
          window.removeEventListener('resize', debouncedHandleResize)
        
      }})

    return (
        <Dialog fullWidth={true} maxWidth="80vw" onClose={handleClose} open={isOpen} className={classes.youtube}>
            <ClickAwayListener onClickAway={handleClose} >
                {/* <BrowserView> */}
                <iframe 
                // width={dimensions.width/2} height="500"
                width={dimensions.width/1.5}
                height={(dimensions.width*56.25/100)/1.5}

                title={src}
                    src={src}
                    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
                {/* </BrowserView>
                <MobileView>
                <iframe width="300" height="250"
                title={src}
                    src={src}
                    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
                </MobileView> */}
            </ClickAwayListener>
        </Dialog>
    )
}