import {createMuiTheme } from '@material-ui/core/styles';
import '../res/fonts.css';

export const Theme = createMuiTheme({
  palette: {
    primary: { main: '#EEEEEE' }, // Purple and green play nicely together.
    secondary: { main: '#F55B61' }, // This is just green.A700 as hex.
  },
  typography: { 
      //useNextVariants: true,
    //   fontFamily: "Inter UI"
      fontFamily: " Quicksand, Montserrat, Inter UI,-apple-system,BlinkMacSystemFont,Roboto,Segoe UI,Helvetica,Arial,sans-serif"
     },
     overrides:{
         '@global':{
             
         }
     }
});
