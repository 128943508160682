import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider'
import { BrowserView, MobileView } from "react-device-detect"

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: "40px",
        clear: "both",
        position: 'fixed',
        bottom: "0px",
        left: "0px",
        width: '100%',
    },
    paper: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#fafafa'
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    footer_divider: {
        marginRight: '20px',
        marginLeft: '30px',
    },
    button: {
        margin: theme.spacing(1),
        textTransform: "none",
        "&:hover": { backgroundColor: "transparent" }
    },
    contactus: {
        cursor: "pointer"
    }
}));

function rsize(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

export default function Footer(props) {
    const classes = useStyles();
    const { setPage } = props;

    const [dimensions, setDimensions] = React.useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
      React.useEffect(() => {
        const debouncedHandleResize = rsize(function handleResize() {
          setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
          })
        }, 300)
    
        window.addEventListener('resize', debouncedHandleResize)
    
        return _ => {
          window.removeEventListener('resize', debouncedHandleResize)
        
      }})

    return (
        <div className={classes.root}>
            <Paper square className={classes.paper}>

                {dimensions.width < 600 ?
                // <MobileView>
                    <Typography variant="subtitle2">
                        <Box
                            letterSpacing={4}
                            fontWeight="fontWeightRegular">
                            2020 Fourier Machines
                    </Box>
                    </Typography>:
                // </MobileView>
                // <BrowserView>
                    <Typography variant="subtitle2">
                        <div className={classes.footer}>
                            <Box
                                letterSpacing={4}
                                fontWeight="fontWeightRegular">
                                2020 Fourier Machines
                    </Box>
                            <Divider orientation="vertical" flexItem className={classes.footer_divider} />

                            {/* <Button
                                disableRipple={true}
                                disableFocusRipple={true}
                                variant="text"
                                onClick={() => { setPage(4) }}
                                className={classes.button}> */}
                            <Box
                                className={classes.contactus}
                                onClick={() => { setPage(4) }}
                                letterSpacing={1}
                                fontWeight="fontWeightRegular">
                                Contact us
                                    </Box>
                            {/* </Button> */}
                        </div>
                    </Typography>
                // </BrowserView>
                }
            </Paper>
        </div>
    );
}