import React from 'react';
import './App.css';
import Header from './components/Header'
import { makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Theme } from './components/Theme'
import Footer from './components/Footer';
import Body from './components/Body';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    // justifyContent: 'center',
    width: "100%",
    height: "100vh",
    minWidth: '600',
    overflow: 'auto'
  },
  app: {
    //display: 'flex',
    //flexDirection: 'column',
    // justifyContent: 'space-between'
  },
  header: {
    verticalAlign: 'top'
  },
  footer: {
  }
}));

function App() {
  const classes = useStyles();

  const [page, setPageX] = React.useState(0)
  const setPage = (x) => {
    setPageX(x)
    // window.scrollTo(0, 0)
  }
    // const [dimensions, setDimensions] = React.useState({
    //     height: window.innerHeight,
    //     width: window.innerWidth
    // })

    // React.useEffect(() => {
    //     function handleResize() {
    //       if(dimensions.height !== window.innerHeight || dimensions.width !== window.innerWidth){
    //         setDimensions({
    //             height: window.innerHeight,
    //             width: window.innerWidth
    //         })
    //       }
    //     }
    //     window.addEventListener('resize', handleResize)
    // })
    //width={dimensions.width} height={dimensions.heigth}

  return (
    <MuiThemeProvider theme={Theme}>
      <div className={classes.root} >
        <div className={classes.app}>
          <Header className={classes.header} setPage={setPage} />
          <Body page={page} setPage={setPage} />
          <Footer className={classes.footer} setPage={setPage} />
        </div>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
