import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AniC from './AniC';
import Myli from './Myli';
import WavB from './WavB';
import ContactUs from './ContactUs'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        clear: "both",
        position: 'fixed',
        top: "45px",
        bottom: '40px',
        left: "0px",
        width: '100%',
        overflow: 'auto',
        background: '#f7f9f9',
    },
    paper: {
        // backgroundColor:'yellow',
        paddingTop: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // height:'100vh',  
        background: '#f7f9f9',
    },
    body: {
        width: '100%',
    }
}));

const my_ref = React.createRef();
export default function Body(props) {
    const classes = useStyles();
    const { page, setPage } = props;
    if (my_ref.current != null)
        my_ref.current.scrollTo(0, 0)

    return (
        <div ref={my_ref} className={classes.root}>
            <Paper elevation={0} square className={classes.paper}>
                {
                    page === 0 ? <div className={classes.body}>
                        <AniC summary={true} setPage={setPage} />
                        <Myli summary={true} setPage={setPage} />
                        <WavB summary={true} setPage={setPage} />
                    </div> : page === 1 ?
                            <AniC summary={false} setPage={setPage} /> :
                            page === 2 ? <Myli summary={false} setPage={setPage} /> :
                                page === 3 ? <WavB summary={false} setPage={setPage} /> :
                                    page === 4 ? <ContactUs /> :
                                        <div />
                }
            </Paper>
        </div>
    );
}